
.modal {
    background: white;
    border: 1px solid #ccc;
    box-shadow: -1rem 1rem 1rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    overflow: auto;
    z-index: 1000;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}


.modal.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}

@media (prefers-reduced-motion) {
    .modal {
        offset-path: none;
    }
}

.modal h2 {
    border-bottom: 1px solid #ccc;
    padding: 1rem;
    margin: 0;
}

.modal .content {
    padding: 1rem;
    overflow-y: initial;
}

.modal .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 0.5rem 1rem;
}


